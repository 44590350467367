define("discourse/plugins/discourse-yearly-review/discourse/initializers/yearly-review-admin-notice", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-yearly-review/discourse/components/yearly-review-admin-notice"], function (_exports, _pluginApi, _yearlyReviewAdminNotice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "yearly-review-admin-notice",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.18.0", api => {
        const siteSettings = container.lookup("service:site-settings");
        if (!siteSettings.yearly_review_enabled) {
          return;
        }

        // Only show this in December of the current year (getMonth is 0-based).
        const now = new Date();
        if (now.getMonth() === 11) {
          api.renderInOutlet("admin-dashboard-top", _yearlyReviewAdminNotice.default);
        }
      });
    }
  };
});